import React from "react";
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShackImage from "../assets/Jess with Murphy in Shack.webp";
import Signature from "../assets/signature.svg";

function About() {
  return (
    <div className="about">
      <Container className="py-5">
        <Row className="align-items-center">
          <Col>
            <Image src={ShackImage} rounded />
          </Col>
          <Col>
            <h1 class="font-weight-light">About me</h1>
            <p>
              My name is Jessica and I love dogs! I am passionate about grooming and your pup's comfort and well-being.
              I have grown up with dogs in my family and now own my own - a black and white collie cross called Murphy with differnet coloured eyes.
              He's daft but obedient, very loving and gets on with everyone and everything.
            </p>
            <p>
              I have a BTec National Diploma in Animal Management specialising in domestic animals.
              Having worked in a grooming salon for three and a half years I have a great deal of hands on experience and deliver a high quality, professional groom, tailored to the individual.
            </p>
            <p>
              I have realised a dream in setting up my own salon and adore meeting new dogs and seeing returning loyal and happy guests. I am a very lucky lady!
            </p>
            <br/>
            <h2 class="font-weight-light">About the salon</h2>
            <p>
              I am delighted to offer a personal one to one grooming service in a state of the art salon with the very latest equipment.
              So, I am comfortable in the knowledge that your woofler will receive a premium groom in the friendliest and most relaxing atmosphere.
            </p>
            <p>
              I have the latest in dog bathing equipment; a hydrobath, which is the combination of a bath tub and power shower.
              It is powerful enough to penetrate even the toughest coats and massages the skin, relieving tired muscles, helping blood circulation and giving relief to older dogs that may have arthiritis, as well as encouraging good health and well being.
            </p>
            <p>
              I use a blaster dryer to hand dry all dogs which blasts away residual dirt, loose fur and dead undercoat leaving your woofler with a super clean, shiny and fluffed-up coat.
            </p>
            <p>
              The Shack is a beautiful, spacious log cabin with plenty of natural light.
              My grooming table is positioned in front of a large window to allow your dog to enjoy views of the garden.
              All my woofler guests will have the freedom to relax and explore the secure garden.
            </p>
            <div className="text-center">
              <Image src={Signature} style={{ height: 119, width: 200 }} alt="Signature"/>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;