import React from "react";
import { withRouter } from "react-router-dom";
import logo from "../assets/logo.png";
import Container from "react-bootstrap/Container";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import Dropdown from "react-bootstrap/Dropdown";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";

function Navigation(props) {
  return (
    <div className="navigation">
      <Navbar bg="light" variant="primary">
        <Container>
          <Navbar.Brand href="/">
            <Image src={logo} alt="Logo" width="auto" height="100px"/>
          </Navbar.Brand>
          <Nav variant="pills" defaultActiveKey="./">
            <LinkContainer to="./" className="pill">
              <Nav.Link className="mx-1 px-4">Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about" className="pill">
              <Nav.Link className="mx-1 px-4">About</Nav.Link>
            </LinkContainer>
              <Dropdown as={NavItem} className="pill">
                <Dropdown.Toggle as={NavLink} className="mx-1 px-4">Services</Dropdown.Toggle>
                <Dropdown.Menu>
                  <LinkContainer to="/dog-grooming">
                    <Dropdown.Item>Dog Grooming</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/reiki">
                    <Dropdown.Item>Reiki</Dropdown.Item>
                  </LinkContainer>
                </Dropdown.Menu>
              </Dropdown>
            <LinkContainer to="/pricing" className="pill">
              <Nav.Link className="mx-1 px-4">Pricing</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/testimonials" className="pill">
              <Nav.Link className="mx-1 px-4">Testimonials</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/gallery" className="pill">
              <Nav.Link className="mx-1 px-4">Gallery</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact" className="pill">
              <Nav.Link className="mx-1 px-4">Contact</Nav.Link>
            </LinkContainer>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default withRouter(Navigation);
