import React from "react";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' 

function DogGrooming() {
  return (
    <div className="dog-grooming">
      <Container className="py-5">
        <Row>
            <h1><FontAwesomeIcon className="pe-2" icon={solid('dog')} />Dog Grooming</h1>
            <p className="pb-3">The Woof Shack offers dog grooming in a <i> safe, relaxing and friendly </i> environment.</p>
        </Row>
        <Row>
          <Col sm={8}>
              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('comments')} />Free Consultation</Card.Title>
                  <Card.Text>
                    Every dog will get a free consulation where I will greet and get to know my guests and discuss grooming requirements.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('soap')} />Wash and Brush Through</Card.Title>
                  <Card.Text>
                    This does not include any trimming or tidying. Great for in between full grooms! This helps to keep your dog clean and tangle free for easier home maintenance.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('eye')} />Eye Tidy</Card.Title>
                  <Card.Text>
                    Allow your dog to see clearly! A quick tidy up in between full grooms to trim away any pokey hairs around the eyes and fringe.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('scissors')} />Clipping, Scissoring and Styling</Card.Title>
                  <Card.Text>
                    I only offer this service to dogs that are pre-bathed as part of a full groom. The best finish is achieved only with a freshly cleaned coat and it prevents blunting of equpiment.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('spray-can-sparkles')} />Cologne</Card.Title>
                  <Card.Text>
                    Every Woofler will get a spritz of cologne after their pampering session, free of charge to keep them smelling gorgeous for days to come!
                    - If any dog has skin irritations or you prefer not to spritzed, please let me know!
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('scissors')} />Nail Trimming</Card.Title>
                  <Card.Text>
                    Nail trimming is included in a full groom and also offered as a stand alone service.
                    Trimmed nails means happy paws!
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('ear-listen')} />Ear Cleaning and Plucking</Card.Title>
                  <Card.Text>
                    Keeping ears clean prevents clogging and infection which can lead to muffled hearing or irritation.
                    For example, drop eared dogs such as poodles may require their ears cleaning / plucking regularly.
                    This can be offered as a stand alone service if necessary but is included as standard in a full groom.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('bug')} />Flea Control</Card.Title>
                  <Card.Text>
                    It is important to keep your dog free of the pesky pests to keep your dog comfortable and healthy.
                    I offer a specialised flea bath with flea shampoo.
                    If fleas are found on your dog during the bathing or grooming process, a flea bath will undertaken.
                    If you are aware that your dog has fleas, please let me know before your visit to The Woof Shack as a flea bomb will have to be used to treat the salon to prevent the spread of fleas to other guests.
                    The cost of the flea bomb will be added onto the cost of the groom.
                    It is advised that all Woofler's are regularly flea treated at home. Prevention is better than cure!
                  </Card.Text>
                </Card.Body>
              </Card>
          </Col>

          <Col sm={4}>
            <Image className="shadow-sm border" style={{'borderRadius':'11px'}} src="https://static.wixstatic.com/media/172519_c597c3f806a64bfd9085abbcf3ed108c.jpg/v1/fill/w_381,h_565,al_c,lg_1,q_80/172519_c597c3f806a64bfd9085abbcf3ed108c.webp" alt="Signature"/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DogGrooming;