import React from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

function Footer() {
    let date = new Date();
    let year = date.getFullYear();
    return (
        <div className="footer bg-light">
            <footer className="pt-5">
                <Container>
                    <Row>
                        <Col>
                            <h4>Opening Hours</h4>
                            <p>Monday - Saturday: Appointment only<br></br>Sunday: Closed</p>
                        </Col>

                        <Col>
                            <h4>Address</h4>
                            <p>44 Park Road<br></br>Norton Canes<br></br>Cannock<br></br>WS11 9PZ</p>
                        </Col>

                        <Col>
                            <h4>Find Us</h4>
                        </Col>
                    </Row>

                    <div className="d-flex justify-content-between py-4 my-4 border-top">
                        <p>© {year} by The Woof Shack</p>
                        
                    </div>
                </Container>
            </footer>
        </div>
    );
}

export default Footer;
