import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Footer, Home, About, DogGrooming, Reiki, Pricing, Testimonials, Gallery, Contact } from "./components";
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/dog-grooming" exact component={() => <DogGrooming />} />
          <Route path="/reiki" exact component={() => <Reiki />} />
          <Route path="/pricing" exact component={() => <Pricing />} />
          <Route path="/testimonials" exact component={() => <Testimonials />} />
          <Route path="/gallery" exact component={() => <Gallery />} />
          <Route path="/contact" exact component={() => <Contact />} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
