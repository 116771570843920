import React from "react";
import Container from 'react-bootstrap/Container';

function Pricing() {
  return (
    <div className="pricing">
      <Container className="py-5">
        <h1 class="font-weight-light">Pricing</h1>
      </Container>
    </div>
  );
}

export default Pricing;