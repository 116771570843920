import React from "react";
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

function Contact() {
  return (
    <div className="contact">
      <Container className="py-5">
        <h1>Drop us a line!</h1>
        <p>The quickest way to get in touch is to give me a ring on 07464 787197!</p>
        <p>
          I will be happy to answer any queries and would love to have a chat with you.
          If you prefer to fill out the contact form above, I will get back to you as soon as I can.
          If I am super busy grooming wooflers, it may take a little while, so please bear with me.
        </p>
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridName">
              <FloatingLabel
              controlId="floatingInput"
              label="Name *"
              className="mb-3">
                <Form.Control type="name" placeholder="name@example.com" />
              </FloatingLabel>
            </Form.Group>
            
            <Form.Group as={Col} controlId="formGridEmail">
              <FloatingLabel
              controlId="floatingInput"
              label="Email address *"
              className="mb-3">
                <Form.Control type="email" placeholder="name@example.com" />
              </FloatingLabel>
            </Form.Group>
          </Row>

          <FloatingLabel
              controlId="floatingInput"
              label="Subject"
              className="mb-3">
                <Form.Control type="subject" placeholder="Subject" />
          </FloatingLabel>

          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
            as="textarea"
            placeholder="Write your message here"
            style={{ height: '100px' }}/>
          </FloatingLabel>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Accept Terms and Conditions" />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
            </Button>
        </Form>
      </Container>
    </div>
  );
}

export default Contact;
