import React from "react";
import Container from 'react-bootstrap/Container';

function Testimonials() {
  return (
    <div className="testimonials">
      <Container className="py-5">
        <h1 class="font-weight-light">Testimonials</h1>
      </Container>
    </div>
  );
}

export default Testimonials;