import React from "react";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from "react-bootstrap/Row";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

function Reiki() {
  return (
    <div className="reiki">
      <Container className="py-5">
        <Row>
          <h1><FontAwesomeIcon className="pe-2" icon={solid('heart')} />Reiki</h1>
          <p className="pb-3">The Woof Shack offers dog grooming in a <i> safe, relaxing and friendly </i> environment.</p>
        </Row>

        <Row>
          <Col sm={8}>
              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('spa')} />Enhances overall well-being</Card.Title>
                  <Card.Text>
                    Just like people, even healthy pets can have occasional physical, emotional and mental imbalances that, if left untreated, can manifest as illness.
                    Periodic Reiki treatments can help maintain your pet’s natural state of well-being and balance.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('house-medical')} />Strengthens the immune system for cancer therapies</Card.Title>
                  <Card.Text>
                    Cancer therapies such as chemotherapy and radiation present stress to an already compromised immune system.
                    Reiki strengthens the immune system to better deal with this additional stress.
                    It helps alleviate or prevent the side effects of conventional cancer treatments and provides pain relief.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('briefcase-medical')} />Accelerates healing following surgery or illness</Card.Title>
                  <Card.Text>
                    Reiki can alleviate and prevent side effects of conventional medications such as pain killers and antibiotics, and provides pain relief and relaxation to facilitate and enhance the body’s natural healing response.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('dog')} />Increases trust and bonding between you and your pet</Card.Title>
                  <Card.Text>
                    People and pets often mirror each others’ physical and emotional states.
                    Animals are natural healers and sometimes take on their person’s problems, often in an attempt to heal them.
                    This happens because of the deep bond shared between a pet and his or her person.
                    Because of the shared energy in such a close relationship, energetic imbalances are shared as well.
                    For optimum healing, joint treatments for people and their pets can often be beneficial.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('hand-holding-heart')} />Can help with behavior issues by promoting relaxation and stress reduction</Card.Title>
                  <Card.Text>
                    Many behavior issues are caused by stress.
                    Reiki has a calming effect on the pet and may help make the pet more receptive to training and behavior modification.
                    Reiki is extremely beneficial for animals with a history of abuse.
                    The gentle touch and the energy can help restore trust in animals who have learned to associate touch with being hurt.
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card className="mb-2 shadow-sm p-2" style={{'borderRadius':'11px'}}>
                <Card.Body>
                  <Card.Title className="heading"><FontAwesomeIcon className="text-muted pe-2" icon={solid('paw')} />Provides comfort and relieves pain, anxiety and fear for terminally ill animals</Card.Title>
                  <Card.Text>
                    Reiki is a wonderful way to facilitate the transition for terminally ill animals and their owners.
                    Often, animals will not allow themselves to transition because they intuitively feel that their person is not ready to let them go.
                    Reiki can help them through this difficult time by allowing a gentle transition.
                  </Card.Text>
                </Card.Body>
              </Card>
          </Col>
          
          <Col sm={4}>
            <Image className="shadow-sm border" style={{'borderRadius':'11px'}} src="https://static.wixstatic.com/media/172519_fecb132bd3014470b5123f6e4b34980c.jpg/v1/fill/w_345,h_458,al_c,q_80,usm_0.66_1.00_0.01/172519_fecb132bd3014470b5123f6e4b34980c.webp" alt="Signature"/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Reiki;