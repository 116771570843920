import React from "react";
import Container from 'react-bootstrap/Container';

function Gallery() {
  return (
    <div className="gallery">
      <Container className="py-5">
        <h1 class="font-weight-light">Gallery</h1>
      </Container>
    </div>
  );
}

export default Gallery;